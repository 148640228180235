<h2 id="timeline" class="text-center mt-5" data-aos="fade-down" >Mon parcours professionnel</h2>
<section  class="timeline">
  <ol>
    <li>
      <div class="bulle" data-aos="zoom-in" style="border-radius: 10px 10px 10px 00px">
        <time>12/2005 - 03/2006</time>
        <h3 class="h5">Agent d'entretien</h3>
        <h4 class="h6">Mairie d'Échilleuse</h4>
        <p>
          <u>Département:</u>
           Loiret (45)
        </p>
        <p>
          <i class="fas fa-asterisk fa-xs text-primary"></i> Entretien de la commune.<br>
          <i class="fas fa-asterisk fa-xs text-primary"></i> Distribution du courrier de la mairie
        </p>
      </div>
    </li>
    <li>
      <div class="bulle" data-aos="zoom-in" style="border-radius: 00px 10px 10px 10px">
        <time>04/2006 - 10/2007</time>
        <h3 class="h5">Électricien</h3>
        <h4 class="h6">ETS Plaza</h4>
        <p>
          <u>Département:</u>
          Loiret (45)
        </p>
        <p>
          <i class="fas fa-asterisk fa-xs text-primary"></i> Réalisation des installations domestiques.<br>
          <i class="fas fa-asterisk fa-xs text-primary"></i> Maintenance d'installations industrielles
        </p>
      </div>
    </li>
    <li>
      <div class="bulle" data-aos="zoom-in" style="border-radius: 10px 10px 10px 00px">
        <time>11/2007 - 08/2008</time>
        <h3 class="h5">Préparateur de commande</h3>
        <h4 class="h6">Interim Planett</h4>
        <p>
          <u>Département:</u>
          Loiret (45)
        </p>
        <p>
          <i class="fas fa-asterisk fa-xs text-primary"></i> Préparation de commande pour Séphora.<br>
          <i class="fas fa-asterisk fa-xs text-primary"></i> Gestion de quai de dé/chargement pour Carrefour"</p>
      </div>
    </li>
    <li>
      <div class="bulle" data-aos="zoom-in" style="border-radius: 00px 10px 10px 10px">
        <time>08/2008 - 03/2009</time>
        <h3 class="h5">Multiples missions d'interim</h3>
        <h4 class="h6">Adecco</h4>
        <p>
          <u>Département:</u>
          Maine et loire (49)
        </p>
        <p>
          <i class="fas fa-asterisk fa-xs text-primary"></i> Multiples missions de courte durée
        </p>
      </div>
    </li>
    <li>
      <div class="bulle" data-aos="zoom-in" style="border-radius: 10px 10px 10px 00px">
        <time>03/2009 - 09/2010</time>
        <h3 class="h5">Agent de maintenance / Aide-bobineur</h3>
        <h4 class="h6">Adecco</h4>
        <p>
          <u>Département:</u>
          Loiret (45)
        </p>
        <p>
          <i class="fas fa-asterisk fa-xs text-primary"></i> Entretien de serres agricole (Monsanto) <br>
          <i class="fas fa-asterisk fa-xs text-primary"></i> Production et préparation de Ouate (Georgia pacific)
        </p>
      </div>
    </li>
    <li>
      <div class="bulle" data-aos="zoom-in" style="border-radius: 00px 10px 10px 10px">
        <time>De 10/2010 à 09/2020</time>
        <h3 class="h5">Électricien</h3>
        <h4 class="h6">ETS Lefevre</h4>
        <p>
          <u>Département:</u>
          Cher (18)
        </p>
        <p><i class="fas fa-asterisk fa-xs text-primary"></i> Aménagement électrique neuf et rénovation domestique, tertiaire et industrielle, <br>
           <i class="fas fa-asterisk fa-xs text-primary"></i> Vente, installation, dépannage d'électromenager</p>
      </div>
    </li>
    <li>
      <div class="bulle" data-aos="zoom-in" style="border-radius: 10px 10px 10px 00px">
        <time>01/2020 - 07/2020</time>
        <h3 class="h5">Développeur Web</h3>
        <h4 class="h6">OpenClassrooms</h4>
        <p>
          <u>Département:</u>
          Cher (18) (à distance)
        </p>
        <p>A l'issue de la formation et de la validation des compétences par un jury organisé par OpenClassrooms,<br>
          nous obtenons le certificat «Développeur web». </p>
      </div>
    </li>
    <li>
      <div class="bulle" data-aos="zoom-in" style="border-radius: 00px 10px 10px 10px">
        <time>Depuis 04/2021</time>
        <h3 class="h5">Développeur Web</h3>
        <h4 class="h6">dev-web.fr</h4>
        <p>
          <u>Département:</u>
          Cher (18)
        </p>
        <p>
          <i class="fas fa-asterisk fa-xs text-primary"></i> Auto-entrepreneur <br>
          <i class="fas fa-asterisk fa-xs text-primary"></i> Création de sites web <br>
          <i class="fas fa-asterisk fa-xs text-primary"></i> Sites virtrine, e-commerce, ...<br>
        </p>
      </div>
    </li>
    <li>
      <div class="bulle" data-aos="zoom-in" style="border-radius: 10px 10px 10px 00px">
        <time>Depuis 03/2022</time>
        <h3 class="h5">Développeur Web</h3>
        <h4 class="h6">Crédit Agricole Centre Loire</h4>
        <p>
          <u>Département:</u>
          Loiret (45)
        </p>
        <p>
          <i class="fas fa-asterisk fa-xs text-primary"></i> Création d'applicatifs web <br>
          <i class="fas fa-asterisk fa-xs text-primary"></i> Gestion tickets/SAV <br>
        </p>
      </div>
    </li>
    <li></li>
  </ol>
</section>
