<section id="intro" class="mb-5">
  <div class="container">
    <div class="left-content animated-element slide-left in-view">
      <div class="textIntro">
        <div class="row">
          <div class="col-lg-12">
            <img data-aos="fade-down" src="../../../assets/bonjour.svg" alt="Bonjour et bienvenue">
            <img class="photo-profil rounded-circle" data-aos="fade-right" src="../../assets/photo_profil.png"
              alt="photo de profil">
            <p class="pres" data-aos="fade-left">Je m’appelle <br><span>Thomas Girard</span></p>
            <p class="pres" data-aos="fade-up">Je suis <br><span>développeur Web</span></p>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <div data-aos="flip-left" data-aos-delay="300" class="card mt-5 bg-light">
              <div class="card-header text-center">
                <strong>
                  A propos de moi
                </strong>
              </div>
              <div class="card-body height">
                <p>
                  Après plus de 15 ans dans le bâtiment, en reconversion professionnelle depuis 2020, dans le domaine du développement web.
                </p>
                <p>
                  Passionné depuis toujours par l'informatique et principalement savoir comment fonctionne une application, j'ai décidé de professionnaliser mon hobby.
                </p>
                <p>
                  En janvier 2020, j'ai commencé à me former avec <a href="https://openclassrooms.com/fr/">OpenClassrooms</a> avec la formation <a href="https://openclassrooms.com/fr/paths/185-developpeur-web"> développeur web</a>
                </p>
                <p>
                  Puis j'ai travaillé sur différents projets annexes que vous pourrez découvrir plus bas. <a href="#projets"><i class="fas fa-arrow-down text-info"></i></a>
                </p>
                <p>

                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div data-aos="flip-right" data-aos-delay="300" class="card mt-5 bg-light">
              <div class="card-header text-center">
                <strong>
                  Mes languages de programmation
                </strong>
              </div>
              <div class="card-body height text-center">
                <span class="h5 text-center">Angular 11 CLI</span>
                <div class="progress-outer">
                    <div class="progress">
                        <div class="progress-bar progress-bar-striped bg-success" style="width:70%;"></div>
                        <div class="progress-value"><span>70</span> %</div>
                    </div>
                </div>
                <span class="h5 text-center">HTML5 /
                  CSS3</span>
                <div class="progress-outer">
                    <div class="progress">
                        <div class="progress-bar progress-bar-striped bg-danger" style="width:85%;"></div>
                        <div class="progress-value"><span>85</span> %</div>
                    </div>
                </div>
                <span class="h5 text-center">JavaScript</span>
                <div class="progress-outer">
                    <div class="progress">
                        <div class="progress-bar progress-bar-striped bg-warning" style="width:75%;"></div>
                        <div class="progress-value"><span>75</span> %</div>
                    </div>
                </div>
                <span class="h5 text-center">Php7</span>
                <div class="progress-outer">
                    <div class="progress">
                        <div class="progress-bar progress-bar-striped" style="width:55%;"></div>
                        <div class="progress-value"><span>55</span> %</div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6 offset-lg-3">
            <div data-aos="flip-up" data-aos-delay="600" class="card mt-2 bg-light">
              <div class="card-header text-center">
                <strong>
                  Mes frameworks de travail
                </strong>
              </div>
              <div class="card-body text-center">
                <span class="h5 text-center">Bootstrap</span>
                <div class="progress-outer">
                    <div class="progress">
                        <div class="progress-bar progress-bar-striped bg-success" style="width:85%;"></div>
                        <div class="progress-value"><span>85</span> %</div>
                    </div>
                </div>
                <span class="h5 text-center">Twig</span>
                <div class="progress-outer">
                    <div class="progress">
                        <div class="progress-bar progress-bar-striped" style="width:35%;"></div>
                        <div class="progress-value"><span>35</span> %</div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
