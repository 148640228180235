<header id="haut" class="px-3 py-2">
  <div class="container">
    <div class="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
      <a href="/" class="d-flex my-2 my-lg-0 me-lg-auto text-decoration-none">
        <img src="../assets/logo_black.png" class="img-fluid img-thumbnail" alt="Logo dev-web.fr" style="width: 100px;">
      </a>

      <ul class="nav col-12 col-lg-auto my-2 justify-content-center text-center my-md-0 text-small">
        <li routerLinkActive="active">
          <a class="link" (click)= 'scrollToId("formations")' class="nav-link py-3" >
            <i class="fas fa-book-open fa-3x"></i>
          </a>
          <span class="text-dark">
            Formation
          </span>
        </li>
        <li routerLinkActive="active">
          <a class="link" (click)= 'scrollToId("parcours")' class="nav-link py-3">
            <i class="fas fa-route fa-3x"></i>
          </a>
          <span class="text-dark">
            Parcours
          </span>
        </li>
        <li routerLinkActive="active">
          <a class="link" (click)= 'scrollToId("projets")' class="nav-link py-3">
            <i class="fas fa-tasks fa-3x"></i>
          </a>
          <span class="text-dark">
            Projets
          </span>
        </li>
        <li routerLinkActive="active">
          <a class="link" (click)= 'scrollToId("contact")' class="nav-link py-3">
            <i class="fas fa-envelope fa-3x"></i>
          </a>
          <span class="text-dark">
            Contact
          </span>
        </li>
      </ul>
    </div>
  </div>
</header>

<!-- Permet de faire le logo pour up en haut de page -->
<div>
  <a id="cRetour" class="cInvisible" (click)= 'scrollToId("haut")'></a>
</div>
  <div class ="app-content">
    <app-about-me></app-about-me>
    <app-educational></app-educational>
    <app-project></app-project>
    <app-timeline></app-timeline>
  </div>

  <footer style="border-top: 3px black solid" class="container3 animate_footer" id="contact" data-spy="">
    <div class="row">
      <div class="col-4 col-md">
        <h3>Plan du site</h3>
        <ul class="list-unstyled text-small">
          <div class="row  text-light">
            <div class="col-md-4">
              <a (click)= 'scrollToId("timeline")' style="cursor: pointer;">Mon Parcours</a>
            </div>
            <div class="col-md-4">
              <a (click)= 'scrollToId("projets")' style="cursor: pointer;">Mes Projets</a>
            </div>
            <div class="col-md-4">
              <a (click)= 'scrollToId("formations")' style="cursor: pointer;">Ma Formation</a>
            </div>
          </div>
        </ul>
      </div>
      <div class="col-4 col-md">
        <h3>Où me trouver</h3>
        <ul class="list-unstyled text-small">
          <div class="row">
            <div class="col-md-3">
              <a  target="_blank" rel="noopener" href="https://www.linkedin.com/in/thomas-g-789bb71a8/"><i class="fab fa-linkedin fa-3x text-muted"></i></a>
            </div>
            <div class="col-md-3">
              <a  target="_blank" rel="noopener" href="https://my.indeed.com/p/pa61pcl"><i class="fab fa-invision fa-3x text-muted"></i></a>
            </div>
            <div class="col-md-3">
              <a  target="_blank" rel="noopener" href="https://github.com/dalton-x/"><i class="fab fa-github-square fa-3x text-muted"></i></a>
            </div>
            <div class="col-md-3">
              <a  target="_blank" rel="noopener" href="mailto:contact@dev-web.fr"><i class="fas fa-envelope fa-3x text-muted"></i></a>
            </div>
          </div>
        </ul>
      </div>
      <div class="col-4 col-md">
        <h3>A propos de moi</h3>
        <ul style="color: white;" class="list-unstyled text-small">
          {{ this.age }} ans. <br>
          Région Centre Val de loire. <br>
          En reconversion professionelle. <br>
          Développeur Web.
        </ul>
      </div>
    </div>
  </footer>
