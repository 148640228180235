<h2 id="projets" class="text-center mb-5 mt-5" data-aos="fade-down" >Ils m'ont fait confiance.</h2>
<section class="mb-5">
  <div class="container">
    <div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel">
      <div class="carousel-indicators">
        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
      </div>
      <div class="carousel-inner">
        <div class="carousel-item active">
          <img src="../../assets/perso/pizzaswing.png" class="picture d-block w-100 rounded" alt="screen pizzaSwing">
          <div class="carousel-caption d-none d-md-block text-dark bg-light rounded">
            <h5>Pizza Swing</h5>
            <p>
              Reprise d'un site web existant et mise au normes web 2021.<br>
              Utilisation de php et mysql sans framework
            </p>
          </div>
        </div>
        <div class="carousel-item">
          <img src="../../assets/perso/laucreats.png" class="picture d-block w-100 rounded" alt="screen Laucreats">
          <div class="carousel-caption d-none d-md-block text-dark bg-light rounded">
            <h5>Laucreats</h5>
            <p>
              Création complète d'un site e-commerce <br>
              Avec personnalisation des objets directement sur le site web.<br>
              Utilisation d'angular et de nodeJS.
            </p>
          </div>
        </div>
      </div>
      <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>
  </div>
</section>