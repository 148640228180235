<h2 id="formations" class="text-center" data-aos="fade-down">Mes projets de formation</h2>
<section class="py-5">
  <div class="container">
    <div class="row m-3">

      <div class="col-lg-6 mt-2">
        <div class="card shadow-lg">
          <div class="card-header text-center text-uppercase">
            Mise en forme d'un site de menus de restaurants
          </div>
          <div class="card-body">
            <h5 class="card-title text-center">Les animations et Sass</h5>
            <ul class="card-text p-2">
              <li>
                En partant de maquette fournit par un client, j'ai dû réaliser le site web avec un cahier des charges définit.
              </li>
              <li>
                La création d'animations et de mise en forme des menus fournit en images pour les rendre au format. <i class="fab fa-html5 text-danger" title="HTML5"></i> et <i class="fab fa-css3-alt text-info" title="CSS3"></i>.
              </li>
            </ul>
          </div>
          <div class="card-footer">
            <a target="_blank" rel="noopener" href="https://projet-3.dev-web.fr/" class="btn btn-primary btn-block">Voir le site</a>
          </div>
        </div>
      </div>

      <div class="col-lg-6 mt-2">
        <div class="card">
          <div class="card-header text-center text-uppercase">
            Reprise d'un site pour le mettre aux normes actuelles
          </div>
          <div class="card-body">
            <h5 class="card-title text-center">L'optimisation et le référencement</h5>
            <ul class="card-text p-2">
              <li>
                Le client a un site web n'étant pas très bien référencé dans les moteurs de recherche.
              </li>
              <li>
                Je me devais de remettre le site web aux normes actuelles et surtout d'enlever toutes les mauvaises pratiques de référencement.
              </li>
            </ul>
          </div>
          <div class="card-footer">
            <a data-bs-toggle="modal" data-bs-target="#modal" class="btn btn-primary btn-block">Voir les rapports</a>
          </div>
        </div>
      </div>
    </div>
    <div class="row m-3">
      <div class="col-lg-6 mt-2">
        <div class="card">
          <div class="card-header text-center text-uppercase">
            Utilisation d'une API serveur
          </div>
          <div class="card-body">
            <h5 class="card-title text-center">Développement du front-end</h5>
            <ul class="card-text p-2">
              <li>
                Le client m'a fourni une API pour se connecter a son serveur de son site e-commerce.
              </li>
              <li>
                J'ai dû faire toute l'interface client en respectant le CDC et le respect de son interface client-serveur.
              </li>
              <li>
                Utilisation de <i class="fab fa-html5 text-danger" title="HTML5"></i> , <i class="fab fa-css3-alt text-info" title="CSS3"></i> et <i class="fab fa-js-square text-warning"></i>
              </li>
            </ul>
          </div>
          <div class="card-footer">
            <a target="_blank" rel="noopener" href="https://projet-5.dev-web.fr/" class="btn btn-primary btn-block">Voir le site</a>
          </div>
        </div>
      </div>

      <div class="col-lg-6 mt-2">
        <div class="card">
          <div class="card-header text-center text-uppercase">
            Création du serveur avec un front déjà existant
          </div>
          <div class="card-body">
            <h5 class="card-title text-center">Développement du back-end</h5>
            <ul class="card-text p-2">
                <li>
                  Le client m'a demandé de faire le serveur de son application de gestion de sauces piquantes.
                </li>
                <li>
                  Je devais reprendre son API et faire le traitement des données :
                  <ul>
                    <li>
                      Utilisateurs : inscription/Connexion.
                    </li>
                    <li>
                     Traitement de l'ajout / suppression des sauces piquantes.
                    </li>
                    <li>
                     Gestion des <i class="fas fa-thumbs-up text-success" title="like"></i> & <i class="fas fa-thumbs-down text-danger" title="unlike"></i> des sauces par les utilisateurs.
                    </li>
                  </ul>
                </li>
                <li>
                  Utilisation de <i class="fab fa-node text-success" title="nodeJS"></i> & de MongoDB
                </li>
            </ul>
          </div>
          <div class="card-footer">
            <a target="_blank" rel="noopener" href="https://projet-6.dev-web.fr/" class="btn btn-primary btn-block">Voir le site</a>
          </div>
        </div>
      </div>
    </div>
    <div class="row m-3">
      <div class="col-lg-6 mt-2 offset-lg-3">
        <div class="card ">
          <div class="card-header text-center text-uppercase">
            Création d'un réseau social
          </div>
          <div class="card-body">
            <h5 class="card-title text-center">Groupomania</h5>
            <ul class="card-text p-2">
              <li>
                Le client m'a contacté pour lui faire un réseau social pour son entreprise en plein développement.
              </li>
              <li>
                Avec un CDC rigoureux et l'utilisation des logos de l'entreprise.
              </li>
              <li>
                L'utilisation du framework <i class="fab fa-angular text-danger"></i> pour le front et de <i class="fab fa-node text-success" title="nodeJS"></i> avec MYSQL et sequelize pour la gestion des données serveur.
              </li>
            </ul>
          </div>
          <div class="card-footer">
            <a target="_blank" rel="noopener" href="https://projet-7.dev-web.fr/" class="btn btn-primary btn-block">Voir le site</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- Modal principal -->
<div class="modal fade" id="modal" aria-hidden="true" aria-labelledby="..." tabindex="-1">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Voir les rapports SEO</h5>
        <button type="button" class="btn-close text-danger" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <ul>
          <li>
            Optimisation du code
          </li>
          <li>
            Optimisation des images en format web actuel
          </li>
          <li>
            Correction des erreurs de code et de script <i class="fab fa-js-square text-warning"></i>
          </li>
        </ul>
      </div>
      <div class="modal-footer">
        <button class="btn btn-primary" data-bs-target="#modalSEOOriginal" data-bs-toggle="modal" data-bs-dismiss="modal">SEO original</button>
        <button class="btn btn-primary" data-bs-target="#modalSEOOptimise" data-bs-toggle="modal" data-bs-dismiss="modal">SEO optimisé</button>
      </div>
    </div>
  </div>
</div>
<!-- modal original -->
<div class="modal" id="modalSEOOriginal" aria-hidden="true" aria-labelledby="..." tabindex="-1">
  <div class="modal-dialog modal-dialog-centered modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Rapport SEO de la version originale du site</h5>
        <a class="btn btn-close text-danger" href="#modal" data-bs-toggle="modal" data-bs-dismiss="modal" role="button"></a>
      </div>
      <div class="modal-body">
        <iframe id="inlineFrameExample"
            title="Inline Frame Example"
            width="1100"
            height="700"
            src="../../../assets/html/rapport_seo_original.html">
        </iframe>
      </div>
      <div class="modal-footer">
        <a class="btn btn-primary" href="#modal" data-bs-toggle="modal" data-bs-dismiss="modal" role="button">Fermer</a>
      </div>
    </div>
  </div>
</div>
<!-- modal optimisé -->
<div class="modal" id="modalSEOOptimise" aria-hidden="true" aria-labelledby="..." tabindex="-1">
  <div class="modal-dialog modal-dialog-centered modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Rapport SEO de la version optimisée du site</h5>
        <a class="btn btn-close text-danger" href="#modal" data-bs-toggle="modal" data-bs-dismiss="modal" role="button"></a>
      </div>
      <div class="modal-body">
        <iframe id="inlineFrameExample"
            title="Inline Frame Example"
            width="1100"
            height="700"
            src="../../../assets/html/rapport_seo_optimise.html">
        </iframe>
      </div>
      <div class="modal-footer">
        <a class="btn btn-primary" href="#modal" data-bs-toggle="modal" data-bs-dismiss="modal" role="button">Fermer</a>
      </div>
    </div>
  </div>
</div>
