import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-educational',
  templateUrl: './educational.component.html',
  styleUrls: ['./educational.component.scss']
})
export class EducationalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  openLink(link: string){
    window.open(link)
  }

}